import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isLogin } from '../Common';
import LayoutPart from '../../Pages/Common/layout/layout'

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    // <Route {...rest} render={props => (

    //     isLogin() ?
    //         <Component {...props} />
    //         : <Redirect to="/" />
    // )} />


    < Route
      {...rest}
      render={props => (isLogin() ? <LayoutPart ><Component {...props} /></LayoutPart> : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />)}
    />
  );
};

export default PrivateRoute;