/* eslint-disable import/no-anonymous-default-export */
export default (state = {}, action) => {
  switch (action.type) {
    case 'RATE_OF_SALE_TREND':
      return {
        ...state,
        rateOfSaleTrend: action.rateOfSaleTrend
      }
    case 'NUMBER_OF_OUTLETS':
      return {
        ...state,
        numberOfOutlets: action.numberOfOutlets
      }
    case 'PERCENTAGE':
      return {
        ...state,
        percentage: action.percentage
      }
    case 'OVERVIEW':
      return {
        ...state,
        overview: action.overview
      }
    case 'COCKTAILS':
      return {
        ...state,
        cocktails: action.cocktails
      }
    case 'TOTALS':
      return {
        ...state,
        totals: action.totals
      }
    case 'LINES':
      return {
        ...state,
        lines: action.lines
      }
    case 'ONLINE':
      return {
        ...state,
        online: action.online
      }
    case 'OFFLINE':
      return {
        ...state,
        offline: action.offline
      }
    case 'POURING':
      return {
        ...state,
        pouring: action.pouring
      }
    case 'RATE_FILTER':
      return {
        ...state,
        rateFilter: action.rateFilter
      }
    case 'SALES_VOLUME':
      return {
        ...state,
        saleVolume: action.saleVolume
      }
    case 'OUTLET_CATEGORY':
      return {
        ...state,
        outletCategory: action.outletCategory
      }
    case 'BRANDS':
      return {
        ...state,
        brands: action.brands
      }
    case 'BRANDS_VOLUME':
      return {
        ...state,
        brandVolume: action.brandVolume
      }
    case 'CUSTOMER_TYPE':
      return {
        ...state,
        customerType: action.customerType
      }
    case 'OVERVIEW_OUTLET':
      return {
        ...state,
        overviewOutlet: action.overviewOutlet
      }
    case 'RATE_OF_SALE_TREND_OUTLET':
      return {
        ...state,
        rateOfSaleTrendOutlet: action.rateOfSaleTrendOutlet
      }
    case 'ALERTS':
      return {
        ...state,
        alerts: action.alerts
      }
    case 'DAILY_VOLUMES_OUTLET':
      return {
        ...state,
        dailyVolumesOutlet: action.dailyVolumesOutlet
      }
    case 'BRANDS_OUTLET':
      return {
        ...state,
        brandsOutlet: action.brandsOutlet
      }
    case 'RATE_OF_SALE_BRANDS_OUTLET':
      return {
        ...state,
        rateOfSaleBrandsOutlet: action.rateOfSaleBrandsOutlet
      }
    case 'OS_MEASURE':
      return {
        ...state,
        OsMeasure: action.OsMeasure
      }
    case 'GET_OUTLET':
      return {
        ...state,
        outletList: action.outletList
      }
    case 'GET_ORDERS_COUNT':
      return {
        ...state,
        ordersCount: action.ordersCount
      }
    case 'GET_ACTIVE_OUTLET_COUNT':
      return {
        ...state,
        activeOutletsCount: action.activeOutletsCount
      }
    case 'GET_AVERAGE_ROS_COUNT':
      return {
        ...state,
        averageRosCount: action.averageRosCount
      }
    case 'GET_REGION_VALUES':
      return {
        ...state,
        regionValues: action.regionValues
      }
    case 'GET_SALES_REPS':
      return {
        ...state,
        salesReps: action.salesReps
      }
    case 'GET_OUTLET_TYPES':
      return {
        ...state,
        outletTypes: action.outletTypes
      }
    case 'GET_OUTLET_LIST':
      return {
        ...state,
        allOutlet: action.allOutlet
      }
    case 'GET_OUTLET_LIST_EXPORT':
      return {
        ...state,
        allOutletExport: action.allOutletExport
      }
    case 'GET_OUTLET_SEARCH':
      return {
        ...state,
        outletSearch: action.outletSearch
      }
    case 'GET_TOTAL_SUMMARY_P4':
      return {
        ...state,
        totalSummaryP4: action.totalSummaryP4
      }
    case 'GET_SEGMENTS_P4':
      return {
        ...state,
        segmentsP4: action.segmentsP4
      }
    case 'GET_ROS_CATEGORIES':
      return {
        ...state,
        rosCategories: action.rosCategories
      }
    case 'GET_PERIOD1_ROS_COUNT':
      return {
        ...state,
        period1RosCount: action.period1RosCount
      }
    case 'OUTLET_CUSTOMER':
      return {
        ...state,
        outLetCustomer: {
          outlet: action.outlet,
          customer: action.customer,
          outlateData: action.outlateDate[0]
        }
      }
    case 'GET_PERIOD2_DATE':
      return {
        ...state,
        period2Date: action.period2Date
      }
    case 'LIST_PROMOTION':
      return {
        ...state,
        promotionList: action.promotionList
      }
    case 'PROMOTION_REPORTS':
      return {
        ...state,
        promotionReports: action.promotionReports
      }
    case 'BREWERY_OUTLET_LIST':
      return {
        ...state,
        breweryOutletList: action.breweryOutletList
      }
    case 'DELETE_PROMOTION':
      return {
        ...state,
        delPromotion: action.delPromotion,
        resStatus: action.resStatus,
        resMessage: action.resMessage
      }
    case 'EDIT_PROMOTION':
      return {
        ...state,
        editPromotion: action.editPromotion,
        resStatus: action.resStatus,
        resMessage: action.resMessage
      }
    case 'STATUS_UPDATE_PROMOTION':
      return {
        ...state,
        statusUpdatePromotion: action.statusUpdatePromotion,
        resStatus: action.resStatus,
        resMessage: action.resMessage
      }
    case 'ADD_PROMOTION':
      return {
        ...state,
        addPromotion: action.addPromotion,
        resStatus: action.resStatus,
        resMessage: action.resMessage
      }
    case 'CLEAR':
      return {
        ...state,
        resStatus: undefined,
        resMessage: undefined
      }
    default:
      return state
  }
}