import moment from "moment";

export const CheckLogin = (props, redirect) => {
    const accessToken = JSON.parse(localStorage.getItem("authUserKG"))
        ? JSON.parse(localStorage.getItem("authUserKG")).access_token
        : "";
    if (accessToken) {
        props.history.push(redirect)
    }
    else {
        localStorage.removeItem("authUserKG")
    }

}

export const clearLogin = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.assign('/')
}

export const isLogin = () => {
    if (localStorage.getItem('token')) {
        return true;
    }

    return false;
}

export const convertDate = (date) => {
    const d = new Date(date)
    return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`
}

export const getDates = (type) => {
    switch (type) {
        case 'Today':
            return { start: moment(), end: moment() }
            break;
        case 'Yesterday':
            const d = moment().subtract(1, 'days')
            return { start: d, end: d }
            break;
        case 'Last_7_Days':
            const last = moment().subtract(7, 'days')
            return { start: last, end: moment() }
            break;
        case 'Last_14_Days':
            const lastP = moment().subtract(14, 'days')
            return { start: lastP, end: moment() }
            break;
        case 'Month_To_Date':
            const startOfMonth = moment().startOf('month')
            const endOfMonth = moment()
            return { start: startOfMonth, end: endOfMonth }
            break;
        case 'Last_Month':
            const lstartOfMonth = moment().subtract(1, 'month').startOf('month')
            const lendOfMonth = moment().subtract(1, 'month').endOf('month')
            return { start: lstartOfMonth, end: lendOfMonth }
            break;
        default:
            break;
    }
}

export const thousandsSeparators = (num) => {
    num = parseInt(num)
    var num_parts = num.toString().split(".")
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    return num_parts.join(".")
}

export const searchFromArray = (data, searchTxt, fieldName) => {
    const searchData = []
    data.filter((item) => {
        item[fieldName].toLowerCase().toString().indexOf(searchTxt.toLowerCase()) > -1 && searchData.push(item)
        return item
    })
    return searchData
}
