import React, { lazy, Suspense } from 'react';
import { Switch, Router } from 'react-router-dom';
import { createStore, applyMiddleware, compose } from 'redux'
import ReduxThunk from 'redux-thunk'
import { Provider } from 'react-redux'
import { createBrowserHistory } from 'history'
import reducers from './reducers'
import PrivateRoute from './Utils/Route/PrivateRoute';
import PublicRoute from './Utils/Route/PublicRoute';

import Loader from './Pages/Common/Loader';

import 'antd/dist/antd.css';
import './Assests/css/style.css';
// import './Assests/css/style.css';

export const history = createBrowserHistory()

const componentEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
export const store = createStore(reducers, {}, componentEnhancers(applyMiddleware(ReduxThunk)))

// const DashboardComponent = lazy(() => import('./Pages/Dashboard'));
// const RateOfSaleComponent = lazy(() => import('./Pages/RateOfSale'));
// const ReportComponent = lazy(() => import('./Pages/Report'));
// const OutletSummaryComponent = lazy(() => import('./Pages/OutletSummary'));
const OutletReportComponent = lazy(() => import('./Pages/OutletReport'));
const Page6Component = lazy(() => import('./Pages/Page6'));
const OutletSummaryComponent = lazy(() => import('./Pages/Page7'));
const LoginPageComponent = lazy(() => import('./Pages/Login'));
const OutletList = lazy(() => import('./Pages/OutletList'));
const PromotionList = lazy(() => import('./Pages/PromotionList'));
const PromotionReports = lazy(() => import('./Pages/PromotionReports'));
const Boxes = lazy(() => import('./Pages/Boxes'));
const BoxesDetail = lazy(() => import('./Pages/BoxesDetail'));

function App() {
  return (
    <div>
      <Provider store={store}>
        <Router history={history}>
          <Switch>
            <Suspense fallback={<Loader />}>
              {/* <PublicRoute restricted={true} component={Page6Component} path="/" exact /> */}
              <PublicRoute restricted={true} component={LoginPageComponent} layout={true} path="/login" exact />
              <PrivateRoute restricted={true} component={Page6Component} layout={true} path="/dashboard" exact />
              <PrivateRoute restricted={true} component={OutletReportComponent} layout={true} path="/outlet-report" exact />
              <PrivateRoute restricted={true} component={OutletSummaryComponent} layout={true} path="/outlet-summary" exact />
              <PrivateRoute restricted={true} component={OutletList} layout={true} path="/outlet-list" exact />
              <PrivateRoute restricted={true} component={PromotionList} layout={true} path="/promotion-list" exact />
              <PrivateRoute restricted={true} component={PromotionReports} layout={true} path="/promotion-reports" exact />
              <PrivateRoute restricted={true} component={Boxes} layout={true} path="/boxes" exact />
              <PrivateRoute restricted={true} component={BoxesDetail} layout={true} path="/box-detail/:id" exact />
              {/* <Redirect to="/login" layout={true} /> */}
              {/* <PrivateRoute component={ChangePasswordComponent} path="/change-password" exact /> */}
            </Suspense>
          </Switch>
        </Router>
      </Provider>
    </div>
  );
}

const token = localStorage.getItem('token')

if (token) {
  console.log("Token found");
  if (history.location.pathname === '/login' || history.location.pathname === '/') {
    history.push('/dashboard')
  }

  // if(history.location.pathname === '/outlet-report') {
  //   history.push('/outlet-report')
  // }
  // if(history.location.pathname === '/outlet-summary') {
  //   history.push('/outlet-summary')
  // }
  // if(history.location.pathname === '/outlet-list') {
  //   history.push('/outlet-list')
  // }
  // if(history.location.pathname === '/boxes') {
  //   history.push('/boxes')
  // }
} else {
  console.log("Token not found");
  // history.push({ pathname: history.location.pathname });
  history.push('/login')
}

export default App;
