const jwt = require("jsonwebtoken");
const publicKey = process.env.REACT_APP_JWT_PUBLIC_KEY;

export function decodeJwt(token) {
    const verifyOptions = {
      issuer: "Draught Guardian",
      subject: "info@draughtguradian.com",
      audience: "http://draughtguardian.com",
      expiresIn: "24h",
      algorithm: ["RS256"],
    };
    try {
      const legit = jwt.verify(token, publicKey, verifyOptions);
      return legit;
    } catch (err) {
      return null;
    }
  }