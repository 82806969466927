import React, { useState } from 'react';
import Sidebar from '../sidebar';
import HeaderBar from '../../header/index';
import { Layout } from 'antd';
import './style.scss';
// import { useDispatch } from 'react-redux';
// import { OutLetCustomer } from '../../../actions/charts';

function LayoutPart({ children }) {
    const [collapsed, setCollapsed] = useState(false);
    const [toggled, setToggled] = useState(false);
    const [filters, setFilters] = useState({})
    // const dispatch = useDispatch()
    
    const handleCollapsedChange = () => {
        setCollapsed(!collapsed);
        // dispatch(OutLetCustomer(973, 973))
    };

    const handleToggleSidebar = (value) => {
        setToggled(value);
    };

    function handleFilterChange(obj) {
        setFilters(obj)
    }

    const { Content } = Layout;
    return (
        <div className={`app ${toggled ? 'toggled' : ''}`}>
            <Sidebar
                collapsed={collapsed}
            />
            <div className={`body ${collapsed ? 'open' : ''}`} >
                <HeaderBar
                    toggled={toggled}
                    collapsed={collapsed}
                    handleToggleSidebar={handleToggleSidebar}
                    handleCollapsedChange={handleCollapsedChange}
                    filterChanged={handleFilterChange}
                />
                
                <div className="body-content">
                    <Content>
                        {/* {children} */}
                        {React.cloneElement(children, { filters })}
                    </Content>
                </div>
            </div>
        </div>
    );
}
export default LayoutPart;