/* eslint-disable linebreak-style */

import axios from 'axios'

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || 'https://draughtguardian-api.com:9705/api/'
})
//'http://localhost:9700/api/' 

instance.interceptors.response.use(response => response, async (error) => {
  // if (error.response && error.response.status === 401) {
  //   await localStorage.removeItem('Token')
  //   store.dispatch({
  //     type: 'TOKEN_LOGIN',
  //     payload: {
  //       token: null
  //     }
  //   })
  // }
  return Promise.reject(error)
})

export default instance

// https://node-dev.housieskill.com/api/v1/
