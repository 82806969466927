import DatePicker from "react-datepicker";
import calendar_2 from '../../Assests/images/calendar_2.svg';


const Calender = ({ handleChangeData, handleSortCut, endDate, startDate, handleClose, calenderType, className = '' }) => {
    return (
        <div className={`calendar_wrapper ${className}`}>
            <ul className="control_list">
                <li>
                    <label>Date From</label>
                    <DatePicker dateFormat='dd-MM-yyyy' selected={startDate} onChange={(date) => handleChangeData(date, 's')} />
                    <img src={calendar_2} alt="" className="datepicker_btn" />
                </li>
                <li>
                    <label>Date To</label>
                    <DatePicker dateFormat='dd-MM-yyyy' selected={endDate} onChange={(date) => handleChangeData(date, 'e')} />
                    <img src={calendar_2} alt="" className="datepicker_btn" />
                </li>
            </ul>
            <ul className="tag_list">
                <li>
                    <label class={calenderType === 'Today' ? 'calender_active' : ''} onClick={() => handleSortCut('Today')}>Today</label>
                </li>
                <li>
                    <label class={calenderType === 'Yesterday' ? 'calender_active' : ''} onClick={() => handleSortCut('Yesterday')}>Yesterday</label>
                </li>
                <li>
                    <label class={calenderType === 'Last_7_Days' ? 'calender_active' : ''} onClick={() => handleSortCut('Last_7_Days')}>Last 7 Days</label>
                </li>
                <li>
                    <label class={calenderType === 'Last_14_Days' ? 'calender_active' : ''} onClick={() => handleSortCut('Last_14_Days')}>Last 14 Days</label>
                </li>
                <li>
                    <label class={calenderType === 'Month_To_Date' ? 'calender_active' : ''} onClick={() => handleSortCut('Month_To_Date')}>Month To Date</label>
                </li>
                <li>
                    <label class={calenderType === 'Last_Month' ? 'calender_active' : ''} onClick={() => handleSortCut('Last_Month')}>Last Month</label>
                </li>
            </ul>
            <div className="text-right px-1">
                <button className="btn calender_ok" onClick={handleClose}>OK</button>
            </div>
        </div>
    )
}
export default Calender;
