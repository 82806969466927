import { Select } from "antd"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { rateFilter, getRegionValues, getSalesReps, getOutletTypes } from "../../../actions/charts"
const { Option } = Select;

function OutletReportFilters({ handleFilter }) {
    const dispatch = useDispatch()
    const [payload, setPayload] = useState({ page: 1, rows: 19, orderby: 'company', asc: true, regions: '', salesreps: '', outlets: '', outlettypes: '', segments: '', roscats: '' })

    const regionValues = useSelector(state => state.charts.regionValues)
    const salesrepsValues = useSelector(state => state.charts.salesReps)
    // const rateFilterData = useSelector(state => state.charts.rateFilter)
    const outletTypes = useSelector(state => state.charts.outletTypes)

    useEffect(() => {
        // dispatch(rateFilter())
        dispatch(getRegionValues(payload))
        dispatch(getSalesReps(payload))
        dispatch(getOutletTypes(payload))
        handleFilter(payload)
    }, [payload])

    function UpdateDropdowns(value, type) {
        if (type === 'regions') {
            setPayload({ ...payload, regions: value?.toString() })
        } else if (type === 'salesreps') {
            setPayload({ ...payload, salesreps: value?.toString() })
        } else if (type === 'outlettypes') {
            setPayload({ ...payload, outlettypes: value?.toString() })
        } else if (type === 'outlets') {
            setPayload({ ...payload, outlets: value?.toString() })
        } else if (type === 'segments') {
            setPayload({ ...payload, segments: value?.toString() })
        } else if (type === 'roscats') {
            setPayload({ ...payload, roscats: value?.toString() })
        }
    }

    return (
        <>
            {/* <Select
                className='small-dropdown ant-select'
                style={{ minWidth: '130px' }}
                defaultValue="Outlet Type"
                size={'small'}
                onChange={(v) => UpdateDropdowns(v, 'outlettypes')}
            >
                {outletTypes?.data?.map((e) => {
                    return (
                        <Option key={e.id} value={e.id}>{e.name}</Option>
                    )
                })}
            </Select> */}
            {
                outletTypes &&
                <Select
                    mode="multiple"
                    showArrow
                    maxTagCount='responsive'
                    allowClear
                    className='ant-select'
                    style={{ minWidth: '150px' }}
                    placeholder="Outlet Type"
                    size={'small'}
                    onChange={(v) => UpdateDropdowns(v, 'outlettypes')}
                    filterSort={(optionA, optionB) => {
                        return optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    }}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>
                    {outletTypes?.data?.map(s => <Option value={s.id}>{s.name}</Option>)}
                </Select>
            }
            {
                regionValues &&
                <Select
                    mode="multiple"
                    showArrow
                    maxTagCount='responsive'
                    allowClear
                    className='ant-select'
                    style={{ minWidth: '160px' }}
                    placeholder="Region"
                    size={'small'}
                    onChange={(v) => UpdateDropdowns(v, 'regions')}
                    filterSort={(optionA, optionB) => {
                        return optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    }}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>
                    {regionValues?.data?.map(s => <Option value={s.id}>{s.name}</Option>)}
                </Select>
            }
            {
                salesrepsValues && <Select
                    mode="multiple"
                    showArrow
                    maxTagCount='responsive'
                    allowClear
                    className='ant-select'
                    onChange={(v) => UpdateDropdowns(v, 'salesreps')}
                    style={{ minWidth: '150px' }}
                    placeholder="Sales Rep"
                    size={'small'}>
                    {salesrepsValues?.data?.map(s => <Option value={s.id}>{s.name}</Option>)}
                </Select>
            }
        </>
    )
}
export default OutletReportFilters