/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */

import axios from '../axios'
import moment from 'moment';
import { decodeJwt } from "../auth"

const storedToken = localStorage.getItem('token')
const headers = {
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    "x-auth-token": storedToken,
  },
};


// const errMsg = 'Server is unavailable.'

export const token = async (username, password) => {
  return await axios.post(`auth/login`, { email: username, password }).then((resp) => {
    if (!resp) {
      return false;
    }
    if (resp.status === 200 || resp.status === 302) {
      const token = resp.data;
      localStorage.setItem("token", token);
      const tokenData = decodeJwt(token);
      if (tokenData !== undefined) {
        localStorage.setItem("userName", tokenData.user);
        return true;
      } else {
        return false;
      }
    } else {
      // console.log(resp);
      return false;
    }
  })
    .catch((error) => {
      return false;
    })
}


export const rateOfSaleTrend = ({ regions = '', salesreps = '', outlettypes = '' }) => (dispatch) => {

  axios.get(`kegtailsweb/rate_of_sale_trend?regions=${regions}&salesreps=${salesreps}&outletcategoryid=${outlettypes}`, headers)
    .then((response) => {
      dispatch({
        type: 'RATE_OF_SALE_TREND',
        rateOfSaleTrend: response.data.data
      })
    }).catch((error) => {
      dispatch({
        type: 'RATE_OF_SALE_TREND',
        error
      })
    })
}

export const numberOfOutlets = ({ dateOne, dateTwo, outletReport: { regions = '', salesreps = '', outlettypes = '' } }) => (dispatch) => {
  axios.get(`kegtailsweb/number_of_outlets?startdate=${dateOne?.start}&enddate=${dateOne?.end}&startdate2=${dateTwo?.start}&enddate2=${dateTwo?.end}&regions=${regions}&salesreps=${salesreps}&outletcategoryid=${outlettypes}`, headers)
    .then((response) => {
      dispatch({
        type: 'NUMBER_OF_OUTLETS',
        numberOfOutlets: response.data.data
      })
    }).catch((error) => {
      dispatch({
        type: 'NUMBER_OF_OUTLETS',
        error
      })
    })
}

export const percentage = ({ dateOne, dateTwo, outletReport: { regions = '', salesreps = '', outlettypes = '' } }) => (dispatch) => {
  axios.get(`kegtailsweb/percentage?startdate=${dateOne?.start}&enddate=${dateOne?.end}&startdate2=${dateTwo?.start}&enddate2=${dateTwo?.end}&regions=${regions}&salesreps=${salesreps}&outletcategoryid=${outlettypes}`, headers)
    .then((response) => {
      dispatch({
        type: 'PERCENTAGE',
        percentage: response.data.data
      })
    }).catch((error) => {
      dispatch({
        type: 'PERCENTAGE',
        error
      })
    })
}

export const overview = (start, end) => (dispatch) => {
  axios.get(`kegtailsweb/overview?startdate=${start}&enddate=${end}`, headers)
    .then((response) => {
      dispatch({
        type: 'OVERVIEW',
        overview: response.data.data
      })
    }).catch((error) => {
      dispatch({
        type: 'OVERVIEW',
        error
      })
    })
}

export const cocktails = (key, value) => (dispatch) => {
  axios.get(`kegtailsweb/cocktails_totals_mtd`, headers)
    .then((response) => {
      dispatch({
        type: 'COCKTAILS',
        cocktails: response.data.data
      })
    }).catch((error) => {
      dispatch({
        type: 'COCKTAILS',
        error
      })
    })
}

export const totals = ({ dateOne, dateTwo, saleFilter }) => (dispatch) => {
  axios.get(`kegtailsweb/totals?measure=${saleFilter}&startdate=${dateOne?.start}&enddate=${dateOne?.end}&startdate2=${dateTwo?.start}&enddate2=${dateTwo?.end}`, headers)
    .then((response) => {
      dispatch({
        type: 'TOTALS',
        totals: response.data.data
      })
    }).catch((error) => {
      dispatch({
        type: 'TOTALS',
        error
      })
    })
}

export const lines = (key, value) => (dispatch) => {
  axios.get(`kegtailsweb/lines_totals_mtd`, headers)
    .then((response) => {
      dispatch({
        type: 'LINES',
        lines: response.data.data
      })
    }).catch((error) => {
      dispatch({
        type: 'LINES',
        error
      })
    })
}

export const online = (key, value) => (dispatch) => {
  axios.get(`kegtailsweb/percentage_online`, headers)
    .then((response) => {
      dispatch({
        type: 'ONLINE',
        online: response.data.data
      })
    }).catch((error) => {
      dispatch({
        type: 'ONLINE',
        error
      })
    })
}

export const offline = (start) => (dispatch) => {
  axios.get(`kegtailsweb/offline_devices?startdate=${start}`, headers)
    .then((response) => {
      dispatch({
        type: 'OFFLINE',
        offline: response.data.data
      })
    }).catch((error) => {
      dispatch({
        type: 'OFFLINE',
        error
      })
    })
}

export const pouring = (key, value) => (dispatch) => {
  axios.get(`kegtailsweb/percentage_pouring`, headers)
    .then((response) => {
      dispatch({
        type: 'POURING',
        pouring: response.data.data
      })
    }).catch((error) => {
      dispatch({
        type: 'POURING',
        error
      })
    })
}

export const rateFilter = () => (dispatch) => {
  axios.get(`outletcategory/readactive`, headers)
    .then((response) => {
      dispatch({
        type: 'RATE_FILTER',
        rateFilter: response.data.data
      })
    }).catch((error) => {
      dispatch({
        type: 'RATE_FILTER',
        error
      })
    })
}

export const salesVolume = (start, end, measure) => (dispatch) => {
  axios.get(`kegtailsweb/sale_volumes?startdate=${start}&enddate=${end}&measure=${measure}`, headers)
    .then((response) => {
      dispatch({
        type: 'SALES_VOLUME',
        saleVolume: response.data.data
      })
    }).catch((error) => {
      dispatch({
        type: 'SALES_VOLUME',
        error
      })
    })
}

export const rosOutletCategory = (start, end) => (dispatch) => {
  axios.get(`kegtailsweb/rate_of_sale_by_outlet_category?startdate=${start}&enddate=${end}`, headers)
    .then((response) => {
      dispatch({
        type: 'OUTLET_CATEGORY',
        outletCategory: response.data.data
      })
    }).catch((error) => {
      dispatch({
        type: 'OUTLET_CATEGORY',
        error
      })
    })
}

export const brands = (start, end) => (dispatch) => {
  axios.get(`kegtailsweb/brands?startdate=${start}&enddate=${end}`, headers)
    .then((response) => {
      dispatch({
        type: 'BRANDS',
        brands: response.data.data
      })
    }).catch((error) => {
      dispatch({
        type: 'BRANDS',
        error
      })
    })
}

export const brandVolume = (start, end, measure, brandid) => (dispatch) => {
  axios.get(`kegtailsweb/volume?startdate=${start}&enddate=${end}&measure=${measure}&brandid=${brandid}`, headers)
    .then((response) => {
      dispatch({
        type: 'BRANDS_VOLUME',
        brandVolume: response.data.data
      })
    }).catch((error) => {
      dispatch({
        type: 'BRANDS_VOLUME',
        error
      })
    })
}

export const customerType = (start, end, brandid) => (dispatch) => {
  axios.get(`kegtailsweb/customer_type?startdate=${start}&enddate=${end}&brandid=${brandid}`, headers)
    .then((response) => {
      dispatch({
        type: 'CUSTOMER_TYPE',
        customerType: response.data.data
      })
    }).catch((error) => {
      dispatch({
        type: 'CUSTOMER_TYPE',
        error
      })
    })
}

export const overviewOutlet = (start, end, outletid, OsMeasureData = 'cocktails') => (dispatch) => {
  axios.get(`kegtailsweb/overview_outlet?startdate=${start}&enddate=${end}&measure=${OsMeasureData}&outletid=${outletid}`, headers)
    .then((response) => {
      dispatch({
        type: 'OVERVIEW_OUTLET',
        overviewOutlet: response.data.data
      })
    }).catch((error) => {
      dispatch({
        type: 'OVERVIEW_OUTLET',
        error
      })
    })
}

export const rateOfSaleTrendOutlet = (outletid) => (dispatch) => {
  axios.get(`kegtailsweb/rate_of_sale_trend_outlet?outletid=${outletid}`, headers)
    .then((response) => {
      dispatch({
        type: 'RATE_OF_SALE_TREND_OUTLET',
        rateOfSaleTrendOutlet: response.data.data
      })
    }).catch((error) => {
      dispatch({
        type: 'RATE_OF_SALE_TREND_OUTLET',
        error
      })
    })
}

export const alerts = (outletid) => (dispatch) => {
  axios.get(`kegtailsweb/alerts?outletid=${outletid}`, headers)
    .then((response) => {
      dispatch({
        type: 'ALERTS',
        alerts: response.data.data
      })
    }).catch((error) => {
      dispatch({
        type: 'ALERTS',
        error
      })
    })
}

export const dailyVolumesOutlet = (start, end, outletid, OsMeasureData = 'cocktails') => (dispatch) => {
  axios.get(`kegtailsweb/daily_volumes_outlet?startdate=${start}&enddate=${end}&measure=${OsMeasureData}&outletid=${outletid}`, headers)
    .then((response) => {
      dispatch({
        type: 'DAILY_VOLUMES_OUTLET',
        dailyVolumesOutlet: response.data.data
      })
    }).catch((error) => {
      dispatch({
        type: 'DAILY_VOLUMES_OUTLET',
        error
      })
    })
}

export const brandsOutlet = (start, end, outletid) => (dispatch) => {
  axios.get(`kegtailsweb/brands_outlet?startdate=${start}&enddate=${end}&outletid=${outletid}`, headers)
    .then((response) => {
      dispatch({
        type: 'BRANDS_OUTLET',
        brandsOutlet: response.data.data
      })
    }).catch((error) => {
      dispatch({
        type: 'BRANDS_OUTLET',
        error
      })
    })
}

export const rateOfSaleBrandsOutlet = (start, end, outletId) => (dispatch) => {
  var currentDate = moment();
  var futureMonth = moment(currentDate).subtract(2, 'months').startOf('month');
  var futureMonthEnd = moment(currentDate).endOf('month');

  futureMonth = futureMonth.format('YYYY-MM-DD')
  futureMonthEnd = futureMonthEnd.format('YYYY-MM-DD')

  axios.get(`kegtailsweb/rate_of_sale_trend_brand_outlet?startdate=${futureMonth}&enddate=${futureMonthEnd}&outletid=${outletId}`, headers)
    .then((response) => {
      dispatch({
        type: 'RATE_OF_SALE_BRANDS_OUTLET',
        rateOfSaleBrandsOutlet: response.data.data
      })
    }).catch((error) => {
      dispatch({
        type: 'RATE_OF_SALE_BRANDS_OUTLET',
        error
      })
    })
}
export const getOutLate = () => (dispatch) => {
  axios.get(`kegtailsweb/outlets`, headers)
    .then((response) => {
      dispatch({
        type: 'GET_OUTLET',
        outletList: response.data.data
      })
    }).catch((error) => {
      dispatch({
        type: 'GET_OUTLET',
        error
      })
    })
}

export const getOutLateList = ({ startdate, enddate, startdate2, enddate2, page, rows, orderby, asc, regions, salesreps, outlets, outlettypes, segments, roscats }) => (dispatch) => {
  axios.get(`kegtailsweb/pg4/outletdetail?startdate=${startdate}&enddate=${enddate}&startdate2=${startdate2}&enddate2=${enddate2}&page=${page}&rows=${rows}&orderby=${orderby}&asc=${asc}&regions=${regions}&salesreps=${salesreps}&outlets=${outlets}&outlettypes=${outlettypes}&segments=${segments}&roscats=${roscats}`, headers)
    .then((response) => {
      dispatch({
        type: 'GET_OUTLET_LIST',
        allOutlet: response.data
      })
    }).catch((error) => {
      dispatch({
        type: 'GET_OUTLET_LIST',
        error
      })
    })
}

export const getOutLateListExport = ({ startdate, enddate, startdate2, enddate2, orderby, asc, regions, salesreps, outlets, outlettypes, segments, roscats }) => (dispatch) => {
  axios.get(`kegtailsweb/pg4/outletdetail?startdate=${startdate}&enddate=${enddate}&startdate2=${startdate2}&enddate2=${enddate2}&page=1&rows=10000&orderby=${orderby}&asc=${asc}&regions=${regions}&salesreps=${salesreps}&outlets=${outlets}&outlettypes=${outlettypes}&segments=${segments}&roscats=${roscats}`, headers)
    .then((response) => {
      dispatch({
        type: 'GET_OUTLET_LIST_EXPORT',
        allOutletExport: response.data
      })
    }).catch((error) => {
      dispatch({
        type: 'GET_OUTLET_LIST_EXPORT',
        error
      })
    })
}
export const getOrdersCount = ({ startdate, enddate, regions, salesreps, outlets = '', segments = '', outlettypes }) => (dispatch) => {
  axios.get(`kegtailsweb/pg4/orders?startdate=${startdate}&enddate=${enddate}&regions=${regions}&salesreps=${salesreps}&outlets=${outlets}&outlettypes=${outlettypes}&segments=${segments}`, headers)
    .then((response) => {
      dispatch({
        type: 'GET_ORDERS_COUNT',
        ordersCount: response.data
      })
    }).catch((error) => {
      dispatch({
        type: 'GET_ORDERS_COUNT',
        error
      })
    })
}
export const getPromotionList = ({searchDate}) => (dispatch) => {
  axios.get(`/promotions/listing?breweryid=17&searchdate=${searchDate}`, headers)
    .then((response) => {
      dispatch({
        type: 'LIST_PROMOTION',
        promotionList: response.data.data
      })
    }).catch((error) => {
      dispatch({
        type: 'LIST_PROMOTION',
        error
      })
    })
}

export const getPromotionReports = ({ searchDate = '', beforeDays, afterDays }) => (dispatch) => {
  axios.get(`/promotions/reports?breweryid=17&searchdate=${searchDate}&before_number_of_days=${beforeDays}&after_number_of_days=${afterDays}`, headers)
    .then((response) => {
      dispatch({
        type: 'PROMOTION_REPORTS',
        promotionReports: response.data.data
      })
    }).catch((error) => {
      dispatch({
        type: 'PROMOTION_REPORTS',
        error
      })
    })
}

export const getbreweryOutletList = () => (dispatch) => {
  axios.get(`/brewery/17/outlets`, headers)
    .then((response) => {
      dispatch({
        type: 'BREWERY_OUTLET_LIST',
        breweryOutletList: response.data.data
      })
    }).catch((error) => {
      dispatch({
        type: 'BREWERY_OUTLET_LIST',
        error
      })
    })
}

export const addPromotionAction = ({ name = '', breweryid = "17", outletIds = '', startDate, endDate }) => (dispatch) => {
  axios.post(`/promotions/add`, { name, breweryid, outletIds, startDate, endDate }, headers)
    .then((response) => {
      dispatch({
        type: 'ADD_PROMOTION',
        addPromotion: response.data,
        resStatus: response.data?.status_code,
        resMessage: response.data?.message
      })
    }).catch((error) => {
      const e = JSON.parse(JSON.stringify(error))
      dispatch({
        type: 'ADD_PROMOTION',
        error,
        resStatus: e.status,
        resMessage: e.message,
      })
    })
}

export const editPromotionAction = ({ name = '', promotionid = "", outletid = '', startDate, endDate }) => (dispatch) => {
  dispatch({ type: 'CLEAR' })
  axios.post(`/promotions/edit`, { name, promotionid, outletid, startDate, endDate }, headers)
    .then((response) => {
      dispatch({
        type: 'EDIT_PROMOTION',
        editPromotion: response.data,
        resStatus: response.data?.status_code,
        resMessage: response.data?.message
      })
    }).catch((error) => {
      const e = JSON.parse(JSON.stringify(error))
      dispatch({
        type: 'EDIT_PROMOTION',
        resStatus: e.status,
        resMessage: e.message,
        error
      })
    })
}

export const statusUpdatePromotionAction = (breweryid = '', promotionid = "", active = '') => (dispatch) => {
  dispatch({ type: 'CLEAR' })
  axios.post(`/promotions/status-update`, { breweryid, promotionid, active }, headers)
    .then((response) => {
      dispatch({
        type: 'STATUS_UPDATE_PROMOTION',
        statusUpdatePromotion: response.data,
        resStatus: response.data?.status_code,
        resMessage: response.data?.message
      })
    }).catch((error) => {
      const e = JSON.parse(JSON.stringify(error))
      dispatch({
        type: 'STATUS_UPDATE_PROMOTION',
        error,
        resStatus: e.status,
        resMessage: e.message,
      })
    })
}


export const delPromotion = (fkbreweryid, fkoutletid, id) => (dispatch) => {
  dispatch({ type: 'CLEAR' })
  axios.delete(`/promotions/delete/${fkbreweryid}/${fkoutletid}/${id}`, headers)
    .then((response) => {
      // console.log({response})
      dispatch({
        type: 'DELETE_PROMOTION',
        delPromotion: response.data,
        resStatus: response.data?.status_code,
        resMessage: response.data?.message
      })
    }).catch((error) => {
      const e = JSON.parse(JSON.stringify(error))
      dispatch({
        type: 'DELETE_PROMOTION',
        error,
        resStatus: e.status,
        resMessage: e.message,
      })
    })
}

export const getActiveOutletsCount = ({ startdate, enddate, regions, salesreps, outlets, outlettypes, segments, roscats }) => (dispatch) => {
  axios.get(`kegtailsweb/pg4/activeoutlets?startdate=${startdate}&enddate=${enddate}&regions=${regions}&salesreps=${salesreps}&outlets=${outlets}&outlettypes=${outlettypes}&segments=${segments}&roscats=${roscats}`, headers)
    .then((response) => {
      dispatch({
        type: 'GET_ACTIVE_OUTLET_COUNT',
        activeOutletsCount: response.data
      })
    }).catch((error) => {
      dispatch({
        type: 'GET_ACTIVE_OUTLET_COUNT',
        error
      })
    })
}

export const getAverageRosCount = ({ startdate, enddate, regions = '', salesreps = '', outlets = '', outlettypes = '', segments = '', roscats = '' }) => (dispatch) => {
  const last12Month = moment().subtract(12, 'months').format('YYYY-MM-DD')
  const today = moment().format('YYYY-MM-DD')

  axios.get(`kegtailsweb/pg4/averageros?startdate=${last12Month}&enddate=${today}&regions=${regions}&salesreps=${salesreps}&outlets=${outlets}&outlettypes=${outlettypes}&segments=${segments}&roscats=${roscats}`, headers)
    .then((response) => {
      dispatch({
        type: 'GET_AVERAGE_ROS_COUNT',
        averageRosCount: response.data
      })
    }).catch((error) => {
      dispatch({
        type: 'GET_AVERAGE_ROS_COUNT',
        error
      })
    })
}

export const getCurrentRosCount = ({ startdate, enddate, regions = '', salesreps = '', outlets = '', outlettypes = '', segments = '', roscat = '' }) => (dispatch) => {
  axios.get(`kegtailsweb/pg4/averageros?startdate=${startdate}&enddate=${enddate}&regions=${regions}&salesreps=${salesreps}&outlets=${outlets}&outlettypes=${outlettypes}&segments=${segments}&roscat=${roscat}`, headers)
    .then((response) => {
      dispatch({
        type: 'GET_AVERAGE_ROS_COUNT',
        averageRosCount: response.data
      })
    }).catch((error) => {
      dispatch({
        type: 'GET_AVERAGE_ROS_COUNT',
        error
      })
    })
}

export const getRegionValues = ({ salesreps = '', outlets = '', outlettypes = '', segments = '' }) => (dispatch) => {
  axios.get(`kegtailsweb/pg4/regions?salesreps=${salesreps}&outlets=${outlets}&outlettypes=${outlettypes}&segments=${segments}`, headers)
    .then((response) => {
      dispatch({
        type: 'GET_REGION_VALUES',
        regionValues: response.data
      })
    }).catch((error) => {
      dispatch({
        type: 'GET_REGION_VALUES',
        error
      })
    })
}

export const getSalesReps = ({ regions = '', outlets = '', outlettypes = '', segments = '' }) => (dispatch) => {
  axios.get(`kegtailsweb/pg4/salesreps?regions=${regions}&outlets=${outlets}&outlettypes=${outlettypes}&segments=${segments}`, headers)
    .then((response) => {
      dispatch({
        type: 'GET_SALES_REPS',
        salesReps: response.data
      })
    }).catch((error) => {
      dispatch({
        type: 'GET_SALES_REPS',
        error
      })
    })
}

export const getOutletTypes = ({ regions = '', outlets = '', salesreps = '' }) => (dispatch) => {
  axios.get(`kegtailsweb/pg4/outlettypes?regions=${regions}&outlets=${outlets}&salesreps=${salesreps}`, headers)
    .then((response) => {
      dispatch({
        type: 'GET_OUTLET_TYPES',
        outletTypes: response.data
      })
    }).catch((error) => {
      dispatch({
        type: 'GET_OUTLET_TYPES',
        error
      })
    })
}

export const getOutletSearch = ({ regions = '', outlettypes = '', salesreps = '', segments = '' }) => (dispatch) => {
  axios.get(`kegtailsweb/pg4/outlets?regions=${regions}&outlettypes=${outlettypes}&salesreps=${salesreps}&segments=${segments}`, headers)
    .then((response) => {
      dispatch({
        type: 'GET_OUTLET_SEARCH',
        outletSearch: response.data
      })
    }).catch((error) => {
      dispatch({
        type: 'GET_OUTLET_SEARCH',
        error
      })
    })
}

export const getTotalSummaryP4 = ({ startdate, enddate, startdate2, enddate2, regions = '', salesreps = '', outlets = '', outlettypes = '', segments = '' }) => (dispatch) => {
  axios.get(`kegtailsweb/pg4/totalsummary?startdate=${startdate}&enddate=${enddate}&startdate2=${startdate2}&enddate2=${enddate2}&regions=${regions}&salesreps=${salesreps}&outlets=${outlets}&outlettypes=${outlettypes}&segments=${segments}`, headers)
    .then((response) => {
      response.data.value = response.data.value.reverse()
      dispatch({
        type: 'GET_TOTAL_SUMMARY_P4',
        totalSummaryP4: response.data
      })
    }).catch((error) => {
      dispatch({
        type: 'GET_TOTAL_SUMMARY_P4',
        error
      })
    })
}

export const getSegmentsP4 = ({ salesreps = '', outlets = '', regions = '', segments = '' }) => (dispatch) => {
  axios.get(`kegtailsweb/pg4/segments?regions=${regions}&outlets=${outlets}&salesreps=${salesreps}&segments=${segments}`, headers)
    .then((response) => {
      dispatch({
        type: 'GET_SEGMENTS_P4',
        segmentsP4: response.data
      })
    }).catch((error) => {
      dispatch({
        type: 'GET_SEGMENTS_P4',
        error
      })
    })
}

export const getRosCategories = () => (dispatch) => {
  axios.get(`kegtailsweb/pg4/roscategories`, headers)
    .then((response) => {
      dispatch({
        type: 'GET_ROS_CATEGORIES',
        rosCategories: response.data
      })
    }).catch((error) => {
      dispatch({
        type: 'GET_ROS_CATEGORIES',
        error
      })
    })
}

export const getPeriod1RosCount = ({ startdate, enddate, regions = '', salesreps = '', outlets = '', outlettypes = '', segments = '', roscats = '' }) => (dispatch) => {
  axios.get(`kegtailsweb/pg4/period1ros?startdate=${startdate}&enddate=${enddate}&regions=${regions}&salesreps=${salesreps}&outlets=${outlets}&outlettypes=${outlettypes}&segments=${segments}&roscats=${roscats}`, headers)
    .then((response) => {
      dispatch({
        type: 'GET_PERIOD1_ROS_COUNT',
        period1RosCount: response.data
      })
    }).catch((error) => {
      dispatch({
        type: 'GET_PERIOD1_ROS_COUNT',
        error
      })
    })
}

export const OsMeasure = (OsMeasure) => (dispatch) => {
  dispatch({
    type: 'OS_MEASURE',
    OsMeasure
  })
}

export const OutLetCustomer = (outlet, customer, outlateDate) => (dispatch) => {
  dispatch({
    type: 'OUTLET_CUSTOMER',
    outlet: outlet,
    customer: customer,
    outlateDate: outlateDate
  })
}

// export const delPromotion = (fkbreweryid, fkoutletid, id) => (dispatch) => {
//   axios.delete(`/promotions/delete/${fkbreweryid}/${fkoutletid}/${id}`, headers)
//     .then((response) => {
//       dispatch({
//         type: 'DELETE_PROMOTION',
//         delPromotion: response.data.data
//       })
//     }).catch((error) => {
//       dispatch({
//         type: 'DELETE_PROMOTION',
//         error
//       })
//     })
// }

export const getPeriod2Date = ({ start, end }) => (dispatch) => {
  axios.get(`kegtailsweb/previous_dates?startdate=${start}&enddate=${end}`, headers)
    .then((response) => {
      dispatch({
        type: 'GET_PERIOD2_DATE',
        period2Date: response.data
      })
    }).catch((error) => {
      dispatch({
        type: 'GET_PERIOD2_DATE',
        error
      })
    })
}