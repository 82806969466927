import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom'
import './style.scss';
import menubar from '../../Assests/images/menu.svg'
import logout from '../../Assests/images/logout.svg'
import { history } from '../../App';
import { Menu } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getOutLate } from '../../actions/charts';
import logoBlack from '../../Assests/images/logo-black.svg';
import HeaderFilters from '../../shared/components/header-filters';
import { convertDate } from '../../Utils/Common';
import moment from 'moment';

const HeaderBar = ({
  collapsed,
  handleCollapsedChange,
  handleToggleSidebar,
  filterChanged
}) => {
  const dispatch = useDispatch()
  const pathname = useLocation();
  // const [outletList, setOutletList] = useState([])
  const outletListData = useSelector(state => state.charts.outletList)
  const handleLogout = () => {
    localStorage.removeItem('token')
    history.push('/login')
  }
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [filter, setFilter] = useState({
    saleFilter: 'cocktails',
    dateOne: { start: convertDate(new Date(moment().startOf('month'))), end: convertDate(new Date()), filter: 'Month To Date', type: 'Month_To_Date' },
    dateTwo: { start: convertDate(new Date(moment().startOf('month'))), end: convertDate(new Date()), filter: 'Month To Date', type: 'Month_To_Date' },
    outletReport: {}
  })

  const [locationPath, setLocationPath] = useState('/login');
  const toggle = () => setPopoverOpen(!popoverOpen);
  const menu = (
    <Menu >
      <Menu.Item key="1" icon={<UserOutlined />}>
        Profile
      </Menu.Item>
      <Menu.Item key="2" icon={<UserOutlined />}>
        Summary
      </Menu.Item>
      <Menu.Item key="3" icon={<UserOutlined />}>
        info
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    !outletListData && dispatch(getOutLate())
  }, [])

  useEffect(() => {
    setLocationPath(pathname.pathname)
  }, [pathname.pathname])

  // useEffect(() => {
  //   if (outletListData?.length) {
  //     let a = outletListData.sort((a, b) => (a.outletname > b.outletname) ? 1 : ((b.outletname > a.outletname) ? -1 : 0))
  //     setOutletList(a)
  //     dispatch(OutLetCustomer(outletListData[0].outletid, 973, getOutlet(outletListData[0].outletid)))
  //   }
  // }, [outletListData])

  // function getOutlet(id) {
  //   return outletList.filter(e => e.outletid === id)
  // }

  function handleChangeFilter(obj) {
    const uFilter = { ...filter, ...obj }
    setFilter(uFilter)
    // filterChanged(uFilter)
  }

  useEffect(() => {
    filterChanged(filter)
  }, [filter])

  function getFilters(page) {
    if (page === '/dashboard') {
      return <HeaderFilters filter={filter} filterChange={handleChangeFilter} saleFilter dateOne dateTwo showDate isDateTwoExpand={false} />
    } else if (page === '/outlet-report') {
      return <HeaderFilters filter={filter} filterChange={handleChangeFilter} dateOne dateTwo outletReport showDate />
    } else if (page === '/outlet-summary') {
      return <HeaderFilters filter={filter} filterChange={handleChangeFilter} dateOne dateTwo showDate />
    } else if (page === '/outlet-list') {
      return <HeaderFilters filter={filter} filterChange={handleChangeFilter} dateOne dateTwo outletList showDate isDateTwoExpand />
    } else {
      return null
    }
  }

  return (
    <div className="header-wrapper">
      <div className="header-left">
        <div className="ml-2 d-flex d-xl-none" onClick={() => handleToggleSidebar(true)}>
          <img src={menubar} alt="menu" onClick={handleCollapsedChange} />
        </div>
        <Link to={'/dashboard'} className='ml-3 d-none d-sm-block'>
          <img src={logoBlack} alt="" className="logo_one" height="25" />
        </Link>
      </div>
      <div className='header-filter d-flex align-items-center'>
        {/* <img src={menubar} alt="menu" className={collapsed ? 'rightPlus' : 'right'} onClick={handleCollapsedChange} /> */}
        {/* {locationPath==='/outlet-summary' && <Popover placement="bottomLeft" content={
          <div className="select-dropdown">
          <div class="ant-popover-title px-0">Outlet</div>
          <div className="box">
            <Select onChange={handleChange} defaultValue={outletList[0]?.outletid} >
              {outletList.map(e => <Option key={e.outletid} value={e.outletid}>{e.outletname}</Option>)}
            </Select>
          </div>
        </div>
        } className="select-popup" trigger="click">
          <img src={menubar} alt="menu" className={collapsed ? 'rightPlus' : 'right'} />
        </Popover>} */}
        {getFilters(pathname?.pathname)}
      </div>
      <div className="header-right">
        <img src={logout} alt="logout" className="mr-5 logout-btn" onClick={handleLogout} />
      </div>
    </div>
  );
};
export default HeaderBar;