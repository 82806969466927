import React, { useState } from 'react';
import { Select } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getOutletTypes, getRegionValues, getRosCategories, getSalesReps, getSegmentsP4 } from '../../../actions/charts';

const { Option } = Select;

function OutletListFilter({ handleFilter, filter }) {
  const dispatch = useDispatch()
  const [payload, setPayload] = useState({ page: 1, rows: 19, orderby: 'company', asc: true, regions: '', salesreps: '', outlets: '', outlettypes: '', segments: '', roscats: '' })

  const regionValues = useSelector(state => state.charts.regionValues)
  const salesReps = useSelector(state => state.charts.salesReps)
  const rosCategories = useSelector(state => state.charts.rosCategories)
  const outletTypes = useSelector(state => state.charts.outletTypes)
  const segments = useSelector(state => state.charts.segments)

  useEffect(() => {
    dispatch(getRegionValues(payload))
    dispatch(getSalesReps(payload))
    dispatch(getRosCategories(payload))
    dispatch(getOutletTypes(payload))
    dispatch(getSegmentsP4(payload))
    handleFilter(payload)
  }, [payload])

  function UpdateDropdowns(value, type) {
    if (type === 'regions') {
      setPayload({ ...payload, regions: value?.toString() })
    } else if (type === 'salesreps') {
      setPayload({ ...payload, salesreps: value?.toString() })
    } else if (type === 'outlettypes') {
      setPayload({ ...payload, outlettypes: value?.toString() })
    } else if (type === 'outlets') {
      setPayload({ ...payload, outlets: value?.toString() })
    } else if (type === 'segments') {
      setPayload({ ...payload, segments: value?.toString() })
    } else if (type === 'roscats') {
      setPayload({ ...payload, roscats: value?.toString() })
    }
  }

  return (
    <>
      {
        regionValues &&
        <Select
          mode="multiple"
          showArrow
          maxTagCount={0}
          allowClear
          className='ant-select'
          style={{ minWidth: '125px' }}
          placeholder="Region"
          size={'small'}
          onChange={(v) => UpdateDropdowns(v, 'regions')}
          filterSort={(optionA, optionB) => {
            return optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
          }}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }>
          {regionValues?.data?.map(s => <Option value={s.id}>{s.name}</Option>)}
        </Select>
      }
      {
        salesReps &&
        <Select
          mode="multiple"
          showArrow
          maxTagCount='responsive'
          allowClear
          className='ant-select'
          style={{ minWidth: '110px' }}
          placeholder="Sales Rep"
          size={'small'}
          onChange={(v) => UpdateDropdowns(v, 'salesreps')}
          filterSort={(optionA, optionB) => {
            return optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
          }}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }>
          {salesReps?.data?.map(s => <Option value={s.id}>{s.name}</Option>)}
        </Select>
      }
      {
        rosCategories &&
        <Select
          mode="multiple"
          showArrow
          maxTagCount='responsive'
          allowClear
          className='ant-select'
          style={{ minWidth: '110px' }}
          placeholder="ROS Cat"
          size={'small'}
          onChange={(v) => UpdateDropdowns(v, 'roscats')}>
          {rosCategories?.data?.map(s => <Option value={s.id}>{s.name}</Option>)}
        </Select>
      }
      {
        outletTypes &&
        <Select
          mode="multiple"
          showArrow
          maxTagCount='responsive'
          allowClear
          className='ant-select'
          style={{ minWidth: '110px' }}
          placeholder="Outlet Type"
          size={'small'}
          onChange={(v) => UpdateDropdowns(v, 'outlettypes')}
          filterSort={(optionA, optionB) => {
            return optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
          }}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }>
          {outletTypes?.data?.map(s => <Option value={s.id}>{s.name}</Option>)}
        </Select>
      }
      {
        segments &&
        <Select
          mode="multiple"
          showArrow
          maxTagCount='responsive'
          allowClear
          className='ant-select'
          style={{ minWidth: '110px' }}
          placeholder="Segment"
          size={'small'}
          onChange={(v) => UpdateDropdowns(v, 'segments')}
          filterSort={(optionA, optionB) => {
            return optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
          }}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }>
          {segments?.data?.map(s => <Option value={s.id}>{s.name}</Option>)}
        </Select>
      }
    </>
  )
}
export default OutletListFilter