import React from 'react';
import {
    ProSidebar,
    Menu,
    SubMenu,
    MenuItem,
    SidebarHeader,
    SidebarFooter,
    SidebarContent
} from 'react-pro-sidebar';
import { Link } from 'react-router-dom';
import logo from '../../Assests/images/logo.svg';
import LOGO from '../../Assests/images/LOGO.png';
import LOGO_SMALL from '../../Assests/images/dg.png';
import Dashboard from '../../Assests/images/dashboard_white.svg';
import DashboardActive from '../../Assests/images/dashboard_active.svg';
import sheet from '../../Assests/images/sheet_white.svg';
import sheetActive from '../../Assests/images/sheet_active.svg'
import Pie from '../../Assests/images/pie_white.svg';
import PieActive from '../../Assests/images/pie_active.svg';
import Discount from '../../Assests/images/discount.svg';
import DiscountActive from '../../Assests/images/discount_active.svg';
import Sale from '../../Assests/images/rateofsale_white.svg';
import SaleActive from '../../Assests/images/rateofsale_active.svg';
import './style.scss';

const Sidebar = ({ collapsed }) => {
    const pathname = window.location.pathname;
    return (
        <ProSidebar
            collapsed={false}
            breakPoint={!collapsed ? "xl" : ''}
        >
            <SidebarHeader>
                <div
                    className='logo'
                    style={{
                        padding: '24px',
                        textTransform: 'uppercase',
                        fontWeight: 'bold',
                        fontSize: 14,
                        letterSpacing: '1px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}
                >
                    <img src={logo} alt="" className="logo_one" height="30" width="200" />
                </div>
            </SidebarHeader>

            <SidebarContent>
                <Menu iconShape="circle">
                    <MenuItem className="sideBarFont" active={pathname.includes('/dashboard')} >
                        <Link to="/dashboard" className="link" />
                        <img src={pathname.includes('/dashboard') ? DashboardActive : Dashboard} alt="" className="mr-2" />Dashboard
                    </MenuItem>
                    <MenuItem active={pathname.includes('/outlet-report')} >
                        <img src={pathname.includes('/outlet-report') ? SaleActive : Sale} alt="" className="mr-2" />Rate of Sale
                        <Link to="/outlet-report" />
                    </MenuItem>
                    <MenuItem active={pathname.includes('/outlet-list')}>
                        <img src={pathname.includes('/outlet-list') ? sheetActive : sheet} alt="" className="mr-2" />
                        Outlet List <Link to="/outlet-list" />
                    </MenuItem>
                    <MenuItem active={pathname.includes('/outlet-summary')}>
                        <img src={pathname.includes('/outlet-summary') ? PieActive : Pie} alt="" className="mr-2" />Outlet Summary
                        <Link to="/outlet-summary" />
                    </MenuItem>
                    {/* <MenuItem active={pathname.includes('/promotion')}>
                        <img src={pathname.includes('/promotion') ? DiscountActive : Discount} alt="" className="mr-2" />Promotion
                        <Link to="/promotion" />
                    </MenuItem> */}
                    <SubMenu title={'Promotion Details'} className={pathname.includes('/promotion-list') || pathname.includes('/promotion-reports') ? 'active' : ''} prefix={<img src={pathname.includes('/promotion-list') || pathname.includes('/promotion-reports') ? DiscountActive : Discount} alt="" className="mr-2" />} defaultOpen={true} open={true}>
                        <MenuItem prefix={<span className='submenu-prefix'></span>} active={pathname.includes('/promotion-list')}>Promotion List<Link to="/promotion-list" /></MenuItem>
                        <MenuItem prefix={<span className='submenu-prefix'></span>} active={pathname.includes('/promotion-reports')}>Promotion Reports<Link to="/promotion-reports" /></MenuItem>
                    </SubMenu>
                    {/* <MenuItem >
                    <img src={maps} alt="" className="mr-2"/>Maps <Link to="/" /></MenuItem>
                    <MenuItem >
                    <img src={sheet} alt="" className="mr-2"/>Reports <Link to="/" /></MenuItem>
                    <MenuItem >
                    <img src={suitcase} alt="" className="mr-2"/>Outlet Report <Link to="/" /></MenuItem>
                    <MenuItem >
                    <img src={user} alt="" className="mr-2"/>User Admin <Link to="/" /></MenuItem> */}
                </Menu>
            </SidebarContent >

            <SidebarFooter style={{ textAlign: 'center' }}>
                <img src={LOGO} alt="" className="big-l logo_one" height="62" width="230" />
                <img src={LOGO_SMALL} alt="" className="small-l logo_one" />
            </SidebarFooter>
        </ProSidebar >
    );
};

export default Sidebar;