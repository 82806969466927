import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isLogin } from '../Common';
// import LayoutPart from '../../Pages/Common/layout/layout'
import { history } from './../../App'


const PublicRoute = ({ component: Component, restricted, ...rest }) => {
    // const requestedURL = rest.location.state;
    return (
        // <Route {...rest} render={props => (
        //     isLogin() && restricted ?
        //         // <Redirect to="/dashboard" />
        //         <LayoutPart >
        //             <Component {...props} />
        //         </LayoutPart>
                
        //         :
        //         <LayoutPart >
        //             <Component {...props} />
        //         </LayoutPart>
        // )} />

        <Route
        {...rest}
        render={props => (isLogin() && restricted ? <Redirect to={{ pathname: '/dashboard', state: { from: props.location } }} /> : <Component {...props} />)}
      />


    );
};

export default PublicRoute;