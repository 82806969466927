/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useState } from 'react';

import './style.scss'
import calendar from '../../../Assests/images/calendar_2.svg';
import { Button } from 'antd';
import Calender from '../../../Pages/Common/Calender';
import { convertDate, getDates } from '../../../Utils/Common';
import OutletReportFilters from '../outlet-report-filters';
import OutletListFilter from '../outlet-list-filter';
import { useDispatch, useSelector } from 'react-redux';
import { getPeriod2Date } from '../../../actions/charts';
import { useHistory } from 'react-router-dom';
// import OutletListFilter from '../outlet-list-filters'

function HeaderFilters({ filter, filterChange, saleFilter, dateOne, dateTwo, outletReport, outletList, showDate, isDateTwoExpand }) {
	const [isOpen1, setIsOpen1] = useState(false)
	const [isOpen2, setIsOpen2] = useState(false)
	const [previousDate, setPreviousDate] = useState({})
	const dispatch = useDispatch()
	const { location } = useHistory()
	const period2Date = useSelector(state => state.charts.period2Date)

	function handleChangeData(date, type, index) {
		// console.log(date, type, index)
		if (type === 's') {
			const dateOne = { ...filter[index], start: convertDate(date), filter: 'Custom' }
			filterChange({ ...filter, [index]: dateOne })
			if (!isDateTwoExpand) dispatch(getPeriod2Date(dateOne))
		} else {
			const dateOne = { ...filter[index], end: convertDate(date), filter: 'Custom' }
			filterChange({ ...filter, [index]: dateOne })
			if (!isDateTwoExpand) dispatch(getPeriod2Date(dateOne))
		}
	}

	function handleSortCut(type, index) {
		const dateOne = {
			...filter[index],
			start: convertDate(getDates(type).start),
			end: convertDate(getDates(type).end),
			filter: type.replaceAll('_', ' '),
			type
		}
		if (!isDateTwoExpand) dispatch(getPeriod2Date(dateOne))
		filterChange({ ...filter, [index]: dateOne })
	}

	useEffect(() => {
		if (!isDateTwoExpand) dispatch(getPeriod2Date(filter?.dateOne))
	}, [])

	useEffect(() => {
		if (period2Date?.data) {
			const date = period2Date?.data
			filterChange({ ...filter, dateTwo: { ...filter?.dateTwo, start: date?.startDatePrevious, end: date?.endDatePrevious } })
			setPreviousDate(period2Date)
		}
	}, [period2Date])
	return (
		<>
			{dateOne && <div className='position-relative calender-icon d-flex'>
				<Button className='header-cal-border' onClick={() => { setIsOpen1(!isOpen1) }} icon={<img src={calendar} alt="Calender" />} />
				{showDate && <div style={{ lineHeight: '16px' }} className='ml-1'>
					{location.pathname === '/outlet-list' ? 'Period 1' : 'Current'}
					<br />
					{filter?.dateOne?.start} - {filter?.dateOne?.end}
				</div>}
				{isOpen1 && <Calender
					handleClose={() => setIsOpen1(!isOpen1)}
					startDate={new Date(filter?.dateOne?.start.replace(/-/g, "/"))}
					endDate={new Date(filter?.dateOne?.end.replace(/-/g, "/"))}
					handleChangeData={(e, t) => handleChangeData(e, t, 'dateOne')}
					handleSortCut={(e) => handleSortCut(e, 'dateOne')}
					calenderType={filter?.dateOne?.type}
					className='first-calender'
				/>}
			</div>}
			{dateTwo && <div className='position-relative calender-icon d-flex'>
				<Button className={location.pathname === '/outlet-list' ? 'header-cal-border' : ''} onClick={() => { setIsOpen2(!isOpen2) }} icon={<img src={calendar} alt="Calender" />} />
				{showDate && <div style={{ lineHeight: '16px' }} className='ml-1'>
					{location.pathname === '/outlet-list' ? 'Period 2' : 'Previous'}
					<br />
					{filter?.dateTwo?.start} - {filter?.dateTwo?.end}
				</div>}
				{isOpen2 && isDateTwoExpand && <Calender
					handleClose={() => setIsOpen2(!isOpen2)}
					startDate={new Date(filter?.dateTwo?.start.replace(/-/g, "/"))}
					endDate={new Date(filter?.dateTwo?.end.replace(/-/g, "/"))}
					handleChangeData={(e, t) => handleChangeData(e, t, 'dateTwo')}
					handleSortCut={(e) => handleSortCut(e, 'dateTwo')}
					calenderType={filter?.dateTwo?.type}
				/>}
			</div>}
			{saleFilter && <ul className="c-tab m-0 d-flex">
				<li>
					<a
						className={filter.saleFilter === 'cocktails' ? 'active' : ''}
						onClick={() => filterChange({ saleFilter: 'cocktails' })}>
						Cocktails
					</a>
				</li>
				<li>
					<a
						className={filter.saleFilter === 'litres' ? 'active' : ''}
						onClick={() => filterChange({ saleFilter: 'litres' })}>
						Litres
					</a>
				</li>
				<li>
					<a
						className={filter.saleFilter === 'kegs' ? 'active' : ''}
						onClick={() => filterChange({ saleFilter: 'kegs' })}>
						Kegs
					</a>
				</li>
			</ul>}
			{outletReport && <OutletReportFilters handleFilter={(e) => {
				filterChange({ ...filter, outletReport: e })
			}} />}
			{outletList && <OutletListFilter filter={filter} handleFilter={(e) => {
				filterChange({ ...filter, outletList: e })
			}} />}
		</>
	)
}
export default HeaderFilters
